import React, { useEffect, useRef, useState } from 'react';
import { useExperience } from '@unisporkal/experiences';
import { ga4Track, ctaInteraction } from '@unisporkal/ga4';
import { useSearchPathBuilder } from '@unisporkal/search-url-builder';
import { useTranslations } from '@unisporkal/localization';
import { useSite } from '@unisporkal/sites';
import {
  Button,
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '@unisporkal/alliance-ui-button';
import { Tag, TagVariantEnum } from '@unisporkal/alliance-ui-tag';
import { DisplayEnum } from '@unisporkal/alliance-ui-flex-box';
import cx from 'classnames';
import SearchPreferenceCookie from '../../../../cookies/searchPreferenceCookie';
import { useSearchBar } from '../../../../hooks';
import gettyStyles from './NLSMessaging.getty.module.scss';
import iStockStyles from './NLSMessaging.istock.module.scss';

const NLSMessaging = () => {
  const nlsMessagingExp = useExperience('NLSMessaging');
  const searchPathBuilder = useSearchPathBuilder();
  const searchBar = useSearchBar();
  const site = useSite();
  const t = useTranslations();
  const cookie = SearchPreferenceCookie.find('sepc');
  const styles = site.isGetty() ? gettyStyles : iStockStyles;
  const isCreative = searchBar.searchParameters.family === 'creative';
  const testVariation = 'nls_messaging_autosuggest';
  const SEARCH_PHRASE = t('enhanced_search_phrase');
  const nlsContainerRef = useRef(null);
  const [layout, setLayout] = useState(null);
  const MIN_WIDTH_HORIZONTAL_LAYOUT = 1068;
  const MAX_WIDTH_MOBILE_LAYOUT = 687;

  const nlsOptOut = () => {
    const contexts = {
      cif: 'image,film',
      ci: 'image',
      cf: 'film',
    };
    let optOut = false;
    Object.keys(contexts).forEach((context) => {
      if (cookie.getValue(context)?.includes('sepc')) {
        optOut = true;
      }
    });
    return optOut;
  };

  const handleOnClick = () => {
    const params = {};
    Object.keys(searchBar.searchParameters).forEach((param) => {
      if (searchBar.searchParameters[param]) {
        params[param] = searchBar.searchParameters[param];
      }
    });

    params.phrase = SEARCH_PHRASE;
    params['show-nls-example'] = true;
    const url = searchPathBuilder.traditionalSearchPath(params);
    const trackingPayload = {
      ui_element_location: testVariation,
      merch_unit_selection: 'nls_messaging',
      merch_unit_interaction: 'click',
      event: 'bannerCtaClick',
    };
    const ga4Event = ctaInteraction(trackingPayload);
    if (Array.isArray(window.tracking_data.merchandising_units)) {
      window.tracking_data.merchandising_units.push(
        'nls_messaging_autosuggest'
      );
    } else {
      window.tracking_data.merchandising_units = ['nls_messaging_autosuggest'];
    }

    ga4Track(ga4Event);

    window.open(url, '_blank').focus();
  };

  const shouldShow = () =>
    isCreative && nlsMessagingExp.isActive() && !nlsOptOut();

  useEffect(() => {
    if (shouldShow()) {
      const trackingPayload = {
        ui_element_location: testVariation,
        merch_unit_selection: 'nls_messaging',
        merch_unit_interaction: 'Shown',
        event: 'bannerShown',
      };

      if (Array.isArray(window.tracking_data.merchandising_units)) {
        window.tracking_data.merchandising_units.push(
          'nls_messaging_autosuggest'
        );
      } else {
        window.tracking_data.merchandising_units = [
          'nls_messaging_autosuggest',
        ];
      }

      ga4Track(trackingPayload);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (nlsContainerRef.current) {
        const currentWidth =
          nlsContainerRef.current.parentElement.getBoundingClientRect().width;

        if (currentWidth > MIN_WIDTH_HORIZONTAL_LAYOUT) {
          setLayout('horizontal');
        } else if (currentWidth < MAX_WIDTH_MOBILE_LAYOUT) {
          setLayout('mobile');
        } else {
          setLayout(null);
        }
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (shouldShow()) {
    return (
      <div
        className={cx(styles.nlsContainer, styles[layout])}
        data-testid="nls-messaging-container"
        ref={nlsContainerRef}
      >
        <div className={styles.left}>
          <Tag
            variant={TagVariantEnum.PRIMARY}
            display={DisplayEnum.INLINEFLEX}
            className={styles.newBadge}
          >
            {layout === 'horizontal'
              ? t('new_enhanced_search')
              : t('new_all_caps')}
          </Tag>
          <span className={styles.title}>
            {site.isGetty()
              ? t('easily_search_creative_content')
              : t('easily_search_using_descriptive_language')}
          </span>
          {layout !== 'mobile' && (
            <div
              data-testid="description"
              className={styles.description}
            >
              {t('nls_modal_description')}
            </div>
          )}
          <div className={styles.ctaWrapper}>
            <Button
              size={ButtonSizeEnum.SMALL}
              variant={ButtonVariantEnum.PRIMARY}
              onClick={handleOnClick}
              data-testid="nls-messaging-button"
              style={{
                fontSize: '14px',
              }}
            >
              {t('example_enhanced_search')}
            </Button>
          </div>
        </div>
        {layout !== 'mobile' && (
          <div
            data-testid="nls-example"
            className={styles.right}
          >
            <div className={styles.searchPhrase}>{SEARCH_PHRASE}</div>
            <div className={styles.gridWrapper}>
              <img
                alt="nls-example"
                src="https://static.gettyimages.com/customer-notifications/nls_modal.svg"
              />
            </div>
          </div>
        )}
      </div>
    );
  }
  return null;
};

export default NLSMessaging;
