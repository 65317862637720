import View from './view';

class SearchBarView extends View {
  ready() {
    return this.status === 'ready';
  }

  updated() {
    return this.status === 'updated';
  }

  imageAndFilmSearch() {
    return this.searchParameters.assettype === 'image,film';
  }

  imageSearch() {
    return this.searchParameters.assettype === 'image';
  }

  filmSearch() {
    return this.searchParameters.assettype === 'film';
  }

  assetType() {
    return this.searchParameters.assettype;
  }

  creativeSearch() {
    return this.searchParameters.family === 'creative';
  }

  editorialSearch() {
    return this.searchParameters.family === 'editorial';
  }

  editorialImageSearch() {
    return this.editorialSearch() && this.imageSearch();
  }

  similarContentSearch() {
    return !!this.searchParameters.morelikethis;
  }

  searchByImageSearch() {
    return !!this.searchParameters.searchbyimage;
  }

  searchByAssetSearch() {
    return !!this.searchParameters.searchbyasset;
  }

  portfolioSearch() {
    return !!this.searchParameters.contributor;
  }

  vectorSearch() {
    return this.searchParameters.assetfiletype === 'eps';
  }

  photographySearch() {
    return this.searchParameters.mediatype === 'photography';
  }

  illustrationSearch() {
    return this.searchParameters.mediatype === 'illustration';
  }

  specialSearch() {
    return (
      this.searchByImageSearch() ||
      this.searchByAssetSearch() ||
      this.portfolioSearch() ||
      this.similarContentSearch()
    );
  }

  hasAssetId() {
    return !!this.searchParameters.assetid;
  }

  isDocked() {
    return this.options.peekyScroll && this.peekyScrollMode === 'docked';
  }

  autoSuggestContainerWidth() {
    return this.config.autoSuggestContainerWidth;
  }
}

export default SearchBarView;
