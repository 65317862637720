import CookieCutter from '@unisporkal/cookie-cutter';

const SearchPreferenceCookie = CookieCutter.define({
  name: 'sp',
  options: {
    maxAgeYears: 1,
  },
  values: {
    search_details: 'sd',
    refinement_panel_state: 'rps',
    editorial_sort: 'es',
    editorial_archival_sort: 'eas',
    editorial_event_sort: 'evs',
    search_grid_layout: 'sgl',
    image_search_grid_layout: 'isgl',
    film_search_grid_layout: 'fsgl',
    saved_filters: 'sf',
    pa_agreement: 'pa',
    hide_creative_banner: 'hcb',
    hide_prestige_banner: 'hpb',
    hide_adobe_plugin_banner: 'hapb',
    creative_image: 'ci',
    editorial_image: 'ei',
    mixed_image: 'mi',
    creative_film: 'cf',
    mixed_film: 'mf',
    analog_film: 'af',
    creative_image_film: 'cif',
    group_by_event: 'gbe',
    gateway_srp: 'gsrp',
  },
});

export default SearchPreferenceCookie;
