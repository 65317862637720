import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { ga4Track, searchInteraction } from '@unisporkal/ga4';
import { useTranslations } from '@unisporkal/localization';
import { useUser } from '@unisporkal/user';
import { useSearchBar, useSpectrum } from '../../../hooks';
import IconCheckmark from '../../../icons/istock/checkmark.svg';
import IconArrow from '../../../icons/istock/arrow.svg';
import iconPhotos from '../../../icons/istock/photos.svg';
import iconImages from '../../../icons/istock/images.svg';
import iconIllustrations from '../../../icons/istock/illustrations.svg';
import iconVectors from '../../../icons/istock/vectors.svg';
import iconVideo from '../../../icons/istock/video.svg';
import iconAllFiles from '../../../icons/istock/allfiles.svg';

const BaseMediaFilter = ({ styles }) => {
  const user = useUser();
  const MEDIA_SELECTED_PARAMS = {
    images: {
      searchParameters: {
        assettype: 'image',
        mediatype: null,
        assetfiletype: null,
      },
      icon: iconImages,
      trackingEvent: 'images',
    },
    photos: {
      searchParameters: {
        assettype: 'image',
        mediatype: 'photography',
        assetfiletype: null,
      },
      icon: iconPhotos,
      trackingEvent: 'images-photos',
    },
    illustrations: {
      searchParameters: {
        assettype: 'image',
        mediatype: 'illustration',
        assetfiletype: null,
      },
      icon: iconIllustrations,
      trackingEvent: 'images-illustrations',
    },
    vectors: {
      searchParameters: {
        assettype: 'image',
        mediatype: 'illustration',
        assetfiletype: 'eps',
      },
      icon: iconVectors,
      trackingEvent: 'images-vectors',
    },
    video: {
      searchParameters: {
        assettype: 'film',
        mediatype: null,
        assetfiletype: null,
      },
      icon: iconVideo,
      trackingEvent: 'video',
    },
  };
  const IMAGE_FILM_MEDIA_PARAM = {
    imagefilm: {
      searchParameters: {
        assettype: 'image,film',
        mediatype: null,
        assetfiletype: null,
      },
      icon: iconAllFiles,
      trackingEvent: 'images-videos',
    },
  };

  const { inExperience } = user.imageFilmGrid;

  const mediaParams = inExperience
    ? { ...IMAGE_FILM_MEDIA_PARAM, ...MEDIA_SELECTED_PARAMS }
    : MEDIA_SELECTED_PARAMS;

  const t = useTranslations();
  const searchBar = useSearchBar();
  const spectrum = useSpectrum();
  const [dropdownClosed, setDropdownClosed] = useState(true);
  const baseMediaContainerRef = useRef(null);

  useEffect(() => {
    if (
      baseMediaContainerRef.current &&
      baseMediaContainerRef.current.getBoundingClientRect().width !== 0
    ) {
      const autoSuggestContainerWidth =
        baseMediaContainerRef.current.getBoundingClientRect().width;
      searchBar.setAutoSuggestContainerWidth(autoSuggestContainerWidth);
    }
  }, [baseMediaContainerRef.current]);

  const gaTracking = (eventName, ga4OptionSelected) => {
    let ga4EventName;
    let ga4SearchSelection;

    if (eventName === 'openDropdown') {
      ga4EventName = 'open_search_asset_family_options';
      ga4SearchSelection = 'asset_family_options';
    }
    if (eventName === 'selectDropdownOption') {
      ga4EventName = 'select_search_asset_family_option';
      ga4SearchSelection = ga4OptionSelected;
    }
    const ga4Event = searchInteraction({
      event_name: ga4EventName,
      search_selection: ga4SearchSelection,
      ui_element_location: 'search_bar_media_filter',
    });

    ga4Track(ga4Event);
  };

  const selectedImageCategory = () => {
    if (searchBar.vectorSearch()) {
      return 'vectors';
    }
    if (searchBar.photographySearch()) {
      return 'photos';
    }
    if (searchBar.illustrationSearch()) {
      return 'illustrations';
    }

    return 'images';
  };

  const selectedMedia = () => {
    if (inExperience && searchBar.imageAndFilmSearch()) {
      return 'imagefilm';
    }
    if (searchBar.filmSearch()) {
      return 'video';
    }
    return selectedImageCategory();
  };

  const toggleDropdown = () => {
    if (dropdownClosed) {
      gaTracking('openDropdown');
    }
    setDropdownClosed(!dropdownClosed);
  };

  const toggleKeyboardDropdown = (e) => {
    if (e.key === 'Enter') {
      toggleDropdown();
    }
  };

  const handleMouseLeave = () => {
    if (dropdownClosed) {
      return;
    }
    setDropdownClosed(true);
  };

  const handleClick = (mediaType) => {
    const { trackingEvent, searchParameters } = mediaParams[mediaType];
    toggleDropdown();
    searchBar.updateSearchBarSearchParameters(searchParameters);
    searchBar.updateSearchBar({ inFocus: false });
    spectrum.sendItemSelectedSignal('media_type_change', mediaType);

    if (!searchBar.options.performSearch) {
      searchBar.setStatusToUpdated();
    }
    gaTracking('selectDropdownOption', trackingEvent, mediaType);
  };

  const handleKeyDown = (mediaType) => (e) => {
    if (e.key === 'Enter') {
      handleClick(mediaType);
    }
  };

  const mediaLabel = (mediaType) => {
    if (mediaType === 'imagefilm') {
      return t('all_content');
    }

    return t(mediaType);
  };

  const mediaDropdownItems = useMemo(
    () =>
      Object.entries(mediaParams).map(([mediaType, config]) => {
        const labelOrder = [
          <config.icon
            key="iconLabel"
            className={styles.labelIcon}
          />,
          <span key="mediaLabel">{mediaLabel(mediaType)}</span>,
          selectedMedia() === mediaType && (
            <IconCheckmark
              key="iconCheckmark"
              className={styles.iconCheckmark}
              viewBox="0 0 100 100"
            />
          ),
        ];
        return (
          <li
            data-testid="media-filter-option"
            key={mediaType}
            className={styles.option}
            onClick={() => handleClick(mediaType)}
            onKeyDown={handleKeyDown(mediaType)}
            role="option"
            aria-selected={selectedMedia() === mediaType}
            aria-label={mediaType}
            tabIndex={0}
          >
            <div
              data-testid="media-filter-option"
              className={styles.holder}
            >
              <span>
                <label className={styles.dropdownLabel}>{labelOrder}</label>
              </span>
            </div>
          </li>
        );
      }),
    [mediaParams]
  );

  const SelectedIcon = mediaParams[selectedMedia()].icon;

  const mediaOptionsStyles = useMemo(() => {
    const cx = classnames.bind(styles.mediaOptions);
    return cx(styles.mediaOptions, {
      [styles.open]: !dropdownClosed,
      [styles.imageFilmGridLabels]: inExperience,
      [styles.imageFilmGridMenuHeight]: inExperience && !dropdownClosed,
    });
  }, [dropdownClosed]);

  return (
    <section
      ref={baseMediaContainerRef}
      className={styles.container}
      onMouseLeave={handleMouseLeave}
      data-testid="section-media-filter"
    >
      <div
        data-testid="media-filter-toggle"
        role="combobox"
        aria-controls="media-filters"
        aria-expanded={!dropdownClosed}
        aria-labelledby="selected-label"
        className={styles.mainLabel}
        onClick={toggleDropdown}
        onKeyDown={toggleKeyboardDropdown}
        tabIndex={0}
      >
        <div className={styles.selectedIconGroup}>
          <SelectedIcon className={styles.selectedIcon} />
          <span
            className={styles.selectedLabel}
            data-testid="selectedLabel"
            id="selected-label"
          >
            {mediaLabel(selectedMedia())}
          </span>
        </div>
        <IconArrow
          className={`${dropdownClosed ? styles.iconClosed : styles.iconOpen}`}
        />

        <div
          data-testid="media-filter-dropdown-container"
          className={mediaOptionsStyles}
        >
          <ul
            data-testid="base-media-fiter-media-dropdown"
            id="media-filters"
            role="listbox"
            aria-labelledby="selected-label"
          >
            {mediaDropdownItems}
          </ul>
        </div>
      </div>
    </section>
  );
};

BaseMediaFilter.propTypes = {
  styles: PropTypes.shape(),
};

BaseMediaFilter.defaultProps = {
  styles: {},
};

export default BaseMediaFilter;
