import { PathUtils } from '@unisporkal/utilities';

const getLastSearchScope = (site) => {
  let assettype;
  let family;

  const storedValue = window.localStorage?.getItem(
    'search.lastSearchParameters'
  );

  if (storedValue) {
    const params = PathUtils.decodeQueryParams(storedValue);
    assettype = params.assettype || 'image';
    family = site.nickname === 'istock' ? 'creative' : params.family;
  }

  return [assettype, family];
};

export default getLastSearchScope;
