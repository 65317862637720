const getDefaultSearchScope = (
  user,
  assetType = 'image',
  family = 'creative'
) => {
  let newAssetType = assetType;
  if (family !== 'film' && user.imageFilmGrid.defaultToImageFilm) {
    newAssetType = 'image,film';
  }
  return [newAssetType, family];
};

export default getDefaultSearchScope;
