import { useEffect } from 'react';
import { useUser } from '@unisporkal/user';
import { useSite } from '@unisporkal/sites';
import getLastSearchScope from '../utils/getLastSearchScope';
import getDefaultSearchScope from '../utils/getDefaultSearchScope';

export default (searchBar) => {
  const user = useUser();
  const site = useSite();

  const applyDefaultSearchScope = () => {
    const [assettype, family] = getDefaultSearchScope(user);
    searchBar.updateSearchBarSearchParameters({
      assettype,
      family,
    });
  };

  const validateRememberedSearchScope = (assettype, family) => {
    let newAssetType = assettype;
    if (newAssetType === 'image,film' && !user.imageFilmGrid.inExperience) {
      newAssetType = 'image';
    } else if (
      newAssetType === 'image' &&
      user.imageFilmGrid.defaultToImageFilm
    ) {
      newAssetType = 'image,film';
    }
    searchBar.updateSearchBarSearchParameters({
      assettype: newAssetType,
      family,
    });
  };

  useEffect(() => {
    if (searchBar.options.rememberLastSearchScope) {
      const [assettype, family] = getLastSearchScope(site, user);
      if (typeof assettype === 'undefined') {
        applyDefaultSearchScope();
      } else {
        validateRememberedSearchScope(assettype, family);
      }
    }
  }, []);
};
