import PropTypes from 'prop-types';

const configPropType = PropTypes.shape({
  config: PropTypes.shape({
    autoSuggestContainerWidth: PropTypes.number,
  }),
  contributor: PropTypes.shape({
    pictureUrl: PropTypes.string,
  }),
  options: PropTypes.shape({
    allowBlankSearches: PropTypes.bool,
    hideOnScroll: PropTypes.bool,
    mobileMediaFilter: PropTypes.bool,
    peekyScroll: PropTypes.bool,
    performSearch: PropTypes.bool,
    showSearchByImageThumb: PropTypes.bool,
    isNLSExample: PropTypes.bool,
  }),
  queryParameters: PropTypes.shape({
    assettype: PropTypes.string,
    assetfiletype: PropTypes.string,
    contributor: PropTypes.string,
    family: PropTypes.string,
    imageurl: PropTypes.string,
    mediatype: PropTypes.string,
    morelikethis: PropTypes.string,
    phrase: PropTypes.string,
    searchbyimage: PropTypes.bool,
    searchbyasset: PropTypes.bool,
  }),
  searchParameters: PropTypes.shape({
    assettype: PropTypes.string,
    assetfiletype: PropTypes.string,
    contributor: PropTypes.string,
    family: PropTypes.string,
    imageurl: PropTypes.string,
    mediatype: PropTypes.string,
    morelikethis: PropTypes.string,
    phrase: PropTypes.string,
    searchbyimage: PropTypes.bool,
    searchbyasset: PropTypes.bool,
    recommendconfig: PropTypes.string,
  }),
  similarAsset: PropTypes.shape({
    colorIndexes: PropTypes.arrayOf(PropTypes.string),
    thumbUrl: PropTypes.string,
  }),
  totalResults: PropTypes.number,
});

export default configPropType;
