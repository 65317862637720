import React from 'react';
import { useSite } from '@unisporkal/sites';
import { useTranslations } from '@unisporkal/localization';
import { useSearchBar, useSpectrum } from '../../../hooks';
import IconPortfolio from '../../../icons/avatar.svg';
import IconClose from '../../../icons/close.svg';
import IconImage from '../../../icons/image.svg';
import styles from './ContextButtons.module.scss';

const ContextButtons = () => {
  const site = useSite();
  const t = useTranslations();
  const searchBar = useSearchBar();
  const spectrum = useSpectrum();

  const clearParameterAndSearch = (parameter) => {
    searchBar.updateSearchBarSearchParameters({ [parameter]: null });
    searchBar.setStatusToUpdated();
    spectrum.sendItemSelectedSignal('clear_parameter_and_search', parameter);
  };

  const similarContentButton = () => {
    if (searchBar.similarContentSearch() && searchBar.similarAsset) {
      return (
        <button
          className={styles.button}
          onClick={() => clearParameterAndSearch('morelikethis')}
          type="button"
        >
          <img
            className={styles.contextImage}
            src={searchBar.similarAsset.thumbUrl}
            alt="similar content thumbnail"
          />
          <span className={styles.text}>{t('search_box.similar_content')}</span>
          <IconClose className={styles.closeIcon} />
        </button>
      );
    }
    return null;
  };

  const portfolioButton = () => {
    if (
      searchBar.portfolioSearch() &&
      searchBar.contributor &&
      !site.isGetty()
    ) {
      return (
        <button
          className={styles.button}
          onClick={() => clearParameterAndSearch('contributor')}
          type="button"
        >
          {searchBar.contributor.pictureUrl ? (
            <img
              className={styles.contextImage}
              src={searchBar.contributor.pictureUrl}
              alt="contributor"
            />
          ) : null}
          {!searchBar.contributor.pictureUrl ? (
            <IconPortfolio
              className={styles.poortfolioIcon}
              viewBox="0 0 134 134"
            />
          ) : null}
          <span className={styles.text}>{t('portfolio')}</span>
          <IconClose className={styles.closeIcon} />
        </button>
      );
    }
    return null;
  };

  const searchByImageButton = () => {
    const showThumbnail = searchBar.options.showSearchByImageThumb;
    const imageUrl = searchBar.searchParameters.imageurl;
    let decodedUrl = '';

    if (imageUrl) {
      // The imageUrl is a base64Url and not a plain base64 string. We need to translate
      // two characters for this to work in the browser.
      const base64ImageUrl = imageUrl.replace(/-/g, '+').replace(/_/g, '/');

      if (typeof window !== 'undefined') {
        decodedUrl = window.atob(base64ImageUrl).toString();
      } else {
        decodedUrl = Buffer.from(base64ImageUrl, 'base64').toString();
      }
    }

    if (searchBar.searchByImageSearch()) {
      return (
        <button
          className={styles.button}
          onClick={() => clearParameterAndSearch('searchbyimage')}
          type="button"
        >
          {showThumbnail ? (
            <img
              className={styles.contextImage}
              src={decodedUrl}
              // eslint-disable-next-line no-return-assign
              onError={(e) => (e.target.style.display = 'none')}
              data-testid="search-by-image-image"
              alt="similar content thumbnail"
            />
          ) : (
            <IconImage className={styles.thumbIcon} />
          )}
          <span className={styles.text}>
            {t('search_box.sbi-result-uploadedimage')}
          </span>
          <IconClose className={styles.closeIcon} />
        </button>
      );
    }
    return null;
  };

  const searchByAssetButton = () => {
    if (searchBar.searchByAssetSearch()) {
      const buttonText =
        searchBar.searchParameters.assettype === 'image'
          ? 'search_box.similar_images'
          : 'search_box.similar_clips';
      return (
        <button
          className={styles.button}
          onClick={() => clearParameterAndSearch('searchbyasset')}
          type="button"
        >
          <img
            className={styles.contextImage}
            src={searchBar.similarAsset.thumbUrl}
            alt="similar content thumbnail"
          />
          <span className={styles.text}>{t(buttonText)}</span>
          <IconClose className={styles.closeIcon} />
        </button>
      );
    }
    return null;
  };

  const shouldShow = () =>
    searchBar.similarContentSearch() ||
    searchBar.searchByImageSearch() ||
    searchBar.searchByAssetSearch() ||
    searchBar.portfolioSearch();

  if (shouldShow()) {
    return (
      <div
        className={styles.container}
        data-testid="container-context-buttons"
      >
        {similarContentButton()}
        {searchByImageButton()}
        {searchByAssetButton()}
        {portfolioButton()}
      </div>
    );
  }
  return null;
};

export default ContextButtons;
