import React, {
  useState,
  useEffect,
  useContext,
  useDeferredValue,
} from 'react';

import { useSite } from '@unisporkal/sites';
import { useSearchBar } from '../../../../hooks';
import { AutoSuggestContext } from '../AutoSuggestContext';

import Suggestion from '../Suggestion/Suggestion';
import NLSMessaging from '../NLSMessaging/NLSMessaging';

import iStockStyles from './Suggestions.istock.module.scss';
import GettyStyles from './Suggestions.getty.module.scss';

const Suggestions = () => {
  const searchBar = useSearchBar();
  const autoSuggest = useContext(AutoSuggestContext);
  const [suggestionsPhrase, setSuggestionsPhrase] = useState('');

  const site = useSite();
  const styles = site.isGetty() ? GettyStyles : iStockStyles;
  const phrase = searchBar.searchParameters.phrase || '';
  const shouldShowSuggestions = useDeferredValue(
    searchBar.inFocus && phrase.length > 0 && autoSuggest.suggestions.length > 0
  );

  const fetchSuggestions = async () => {
    try {
      const results = await autoSuggest.getSuggestions(phrase);
      autoSuggest.setSuggestions(results);
      setSuggestionsPhrase(phrase);
    } catch (err) {
      autoSuggest.setSuggestions([]);
      setSuggestionsPhrase(null);
    } finally {
      autoSuggest.setKeyIndex(-1);
    }
  };

  const shouldFetchSuggestions = () =>
    searchBar.inFocus &&
    phrase !== '' &&
    (autoSuggest.keyIndex === -1 || !autoSuggest.suggestions.includes(phrase));

  useEffect(() => {
    if (shouldFetchSuggestions()) {
      fetchSuggestions();
    }
  }, [searchBar.searchParameters.phrase, searchBar.inFocus]);

  const isKeyboardSelected = (index) => autoSuggest.keyIndex === index;

  const getSuggestions = () =>
    autoSuggest.suggestions.map((suggestion, index) => {
      const key = suggestion.replace(/\s+/g, '-');
      let withRemove = false;
      if (
        autoSuggest.recentSearches.some(
          (search) => suggestion.toLowerCase() === search.phrase.toLowerCase()
        )
      ) {
        withRemove = true;
      }
      return (
        <Suggestion
          suggestion={suggestion}
          suggestionsPhrase={suggestionsPhrase}
          withRemove={withRemove}
          key={key}
          selected={isKeyboardSelected(index)}
          index={index}
        />
      );
    });

  if (shouldShowSuggestions) {
    return (
      <div className={styles.container}>
        <ul
          className={styles.list}
          data-testid="suggestions-list"
        >
          {getSuggestions()}
        </ul>
        <NLSMessaging />
      </div>
    );
  }
  return null;
};

export default Suggestions;
