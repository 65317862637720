import React from 'react';
import { DismissibleTooltip } from '@unisporkal/tooltip';
import { ga4Track, tooltipInteraction } from '@unisporkal/ga4';
import { useSite } from '@unisporkal/sites';
import { useTranslations } from '@unisporkal/localization';
import { useSearchBar } from '../../../hooks';
import GettyStyles from './NLSTooltip.getty.module.scss';
import iStockStyles from './NLSTooltip.istock.module.scss';

const NLSTooltip = () => {
  const t = useTranslations();
  const searchBar = useSearchBar();
  const site = useSite();
  const styles = site.isGetty() ? GettyStyles : iStockStyles;

  const trackViewed = () => {
    const ga4Event = tooltipInteraction({
      tooltip_interaction: 'tooltip_shown',
      tooltip_name: 'natural-language-searchbar',
      ui_element_location: `natural_language_searchbar_tooltip_${site.nickname}`,
    });
    ga4Track(ga4Event);
  };

  return (
    <DismissibleTooltip
      isTooltipVisible={searchBar.options.isNLSExample}
      className={styles.dismissibleTooltip}
      localStorageKey="natural_language_searchbar_tooltip"
      name="natural-language-searchbar"
      placement="bottom"
      trackViewed={trackViewed}
    >
      <div data-testid="nls-tooltip-desc">
        {t('search_box.can_be_as_descriptive')}
      </div>
    </DismissibleTooltip>
  );
};

export default NLSTooltip;
